import jwt_decode from 'jwt-decode'
import router from '../router'
import eventHub from '@/utils/eventHub'
import { api } from '@/utils/axios'
import * as Auth from '@/utils/auth'

const store = {
  namespaced: true,
  state: {
    tokenRequested: false,
    user: null,
    subscribeEmail: ''
  },
  getters: {
    isSignedIn: (state) => !!state.user,
    isProAccount: (state) => !!state.user?.proAccount,
    tokenRequested: (state) => state.tokenRequested,
    user: (state) => state.user,
    subscribeEmail: (state) => state.subscribeEmail
  },
  mutations: {
    setTokenRequested(state, tokenRequested) {
      state.tokenRequested = tokenRequested
    },
    setUser(state, user) {
      state.user = user
    },
    setSubscribeEmail(state, email) {
      state.subscribeEmail = email
    },
    setUserProAccount(state) {
      state.user = { ...state.user, proAccount: true }
    }
  },
  actions: {
    async requestToken({ commit }, email) {
      await api.get(`/api/auth/token?email=${email}`)
      commit('setTokenRequested', true)
    },
    async verifyToken({ commit }, body) {
      const { data: jwt } = await api.post('/api/auth/verify', body)
      const user = jwt_decode(jwt)
      Auth.saveJwtToStorage(jwt)
      commit('setUser', user)
    },
    async googleHandler({ commit }, token) {
      const { data: jwt } = await api.post('/api/auth/google', token)
      const user = jwt_decode(jwt)
      commit('setUser', user)
      Auth.saveJwtToStorage(jwt)
      this.commit('setModal', null)
      window.location.reload()
    },
    setJwt({ dispatch }, jwt) {
      Auth.saveJwtToStorage(jwt)
      dispatch('setUser', jwt)
    },
    setUser({ commit }, jwt) {
      const user = jwt_decode(jwt)
      commit('setUser', user)
    },
    checkStorage({ commit }) {
      const jwt = Auth.getJwtFromStorage()
      if (jwt) {
        const user = jwt_decode(jwt)
        commit('setUser', user)
      }
    },
    async refreshUser({ commit }, timeout = 6000) {
      setTimeout(async () => {
        const { data: jwt } = await api.post('/api/auth/refresh')
        const user = jwt_decode(jwt)
        commit('setUser', user)
        Auth.saveJwtToStorage(jwt)
      }, timeout)
    },
    signOut({ commit }) {
      Auth.deleteJwtFromStorage()

      commit('setUser', null)
      this.commit('profile/resetState')
      this.commit('workbench/resetSignOut')
      this.commit('setModal', null)

      eventHub.$emit('notify', {
        group: 'warning',
        title: 'Logged out of PatternKid.'
      })
      router.push('/workbench')
    }
  }
}

export default store
