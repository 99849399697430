import store from '@/store'
import { clickShape } from './click'

export const dropShape = (e) => {
  clickShape(e, (object) => {
    const { angle: rotation } = object._objects[1]
    store.commit('workbench/setSelectedColour', object.getFill())
    store.commit('workbench/setSelectedTool', { ...object.getShape(), rotation })
  })
}
