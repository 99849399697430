import store from '@/store'
import { shapes } from 'pattern'

const getCoord = (pointer) => {
  const border = store.getters['workbench/border']

  return pointer < border ? -200 : pointer - border - ((pointer - border) % 200)
}

export const getGridCoords = (e) => {
  const pointer = window.canvas.getPointer(e)

  const left = getCoord(pointer.x) / 200
  const top = getCoord(pointer.y) / 200
  const coords = [left, top]

  return coords
}

export const addShape = async (e) => {
  const shape = shapes.getShapeByName(
    store.getters['workbench/selectedTool'].name,
    store.getters['workbench/outline'] / (e.target?.scaleX || 1)
  )
  shape.rotation = store.getters['workbench/selectedTool'].rotation || 0

  const config = {
    padding: store.getters['workbench/padding'],
    border: store.getters['workbench/border'],
    opacity: store.getters['workbench/opacity']
  }
  const ShapeBuilder = new shapes.builder(config)

  const [col, row] = getGridCoords(e)
  const colour = store.getters['workbench/selectedColour']

  const shapeObject = await ShapeBuilder.addShape(null, row, col, shape, colour)

  window.fabric.util.enlivenObjects([shapeObject], (objects) => {
    const origRenderOnAddRemove = canvas.renderOnAddRemove
    window.canvas.renderOnAddRemove = false

    canvas.add(objects[0])

    canvas.renderOnAddRemove = origRenderOnAddRemove
    canvas.renderAll()
  })
}
