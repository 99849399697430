import store from '@/store'
import { getRandomInt } from '@/services/random'

export const randomGradient = async () => {
  const colours = await store.dispatch('workbench/randomColourSet', 2)
  const rotation = getRandomInt(0, 3) * 90

  const gradient = {
    colours,
    percentages: [1, 100],
    rotation,
    stringValue: `linear-gradient(${rotation}deg ,${colours[0]} 1%, ${colours[1]} 100%)`,
    arrayValue: [
      { percentage: 1, colour: colours[0] },
      { percentage: 100, colour: colours[1] }
    ]
  }
  store.commit('workbench/addGradient', gradient)
}
