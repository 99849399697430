import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import settings from '@/utils/settings'

import auth from './auth.store'
import profile from './profile.store'
import workbench from './workbench.store'

Vue.use(Vuex)

const store = new Vuex.Store({
  plugins: [
    createPersistedState({
      storage: window.sessionStorage
    })
  ],
  debug: false,
  modules: {
    auth,
    profile,
    workbench
  },
  state: { modal: null },
  getters: {
    isMobile: () => settings.screen === 'mobile',
    isTablet: () => settings.screen === 'tablet',
    isTouch: () => ['mobile', 'tablet'].includes(settings.screen),
    modal: (state) => state.modal
  },
  mutations: {
    setModal(state, modal) {
      state.modal = modal
    }
  }
})

export default store
