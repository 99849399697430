<template>
  <div>
    <div id="profile-container" class="container is-vscrollable">
      <Header />
      <Tabs />
      <hr class="has-margin-bottom-48" />
      <div class="has-margin-bottom-48">
        <transition>
          <PatternList v-if="profileTab === 'patterns'" />
        </transition>
        <transition>
          <CollectionList v-if="profileTab === 'collections'" />
        </transition>
        <transition>
          <ImageList v-if="profileTab === 'images'" />
        </transition>
        <transition>
          <PaletteList v-if="profileTab === 'palettes'" />
        </transition>
        <transition>
          <ProfileAbout v-if="profileTab === 'about'" />
        </transition>
        <transition>
          <ProfilePlugins v-if="profileTab === 'plugins'" />
        </transition>
      </div>
    </div>
    <div id="profile-navbar">
      <Navbar />
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'Profile',
  components: {
    Navbar: () => import('@/components/navbar/Navbar.vue'),
    Header: () => import('@/components/profile/ProfileHeader.vue'),
    Tabs: () => import('@/components/profile/ProfileTabs.vue'),
    PatternList: () => import('@/components/profile/ProfilePatternList.vue'),
    CollectionList: () => import('@/components/profile/ProfileCollectionList.vue'),
    ImageList: () => import('@/components/profile/ProfileImageList.vue'),
    PaletteList: () => import('@/components/profile/ProfilePaletteList.vue'),
    ProfileAbout: () => import('@/components/profile/ProfileAbout.vue'),
    ProfilePlugins: () => import('@/components/profile/ProfilePlugins.vue')
  },
  async mounted() {
    await Promise.allSettled([
      this.getCollections(),
      this.getPatterns(),
      this.getPalettes(),
      this.getImages()
    ])
  },
  computed: {
    profileTab() {
      return this.$route.params.tab
    }
  },
  methods: {
    ...mapActions('profile', ['getPatterns', 'getCollections', 'getPalettes', 'getImages'])
  }
}
</script>
<style>
#profile-navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 57px;
}

#profile-container {
  position: fixed;
  top: 57px;
  bottom: 0;
  left: 0;
  right: 0;
}
</style>
