<template>
  <div>
    <PatternLoading />
    <div id="pattern-container" class="has-margin-0">
      <PatternContainer />
    </div>
    <div id="controls" class="has-background-white" :style="patternContainerStyle">
      <Controls />
    </div>
    <div id="sidebar" class="has-border-right-light has-background-white" :style="sidebarStyle">
      <Sidebar />
    </div>
    <div v-if="false" id="loadbar" class="has-border-left-light">
      <Loadbar />
    </div>
    <transition name="fadein">
      <ZoomBar v-if="displayOpen === 'zoombar'" />
    </transition>
    <div
      id="footer-controls"
      class="has-background-white"
      style="box-shadow: -1px 0px 0px 0px whitesmoke"
    >
      <FooterControls />
    </div>
    <FadeButton
      id="sidebar-button"
      :action="toggleSidebar"
      :icons="{ true: 'angle-left', false: 'tools' }"
      :state="sidebarOpen"
      colour="is-white"
    />
    <div id="settings-button" :style="settingsStyle">
      <div
        @click="$router.push('/settings')"
        class="button is-white has-shadow has-text-weight-semibold has-margin-left-8"
      >
        <span class="icon"><i class="fas fa-cog"></i></span><span>Settings</span>
      </div>
    </div>
    <div id="navbar" class="has-background-white">
      <Navbar />
    </div>
    <transition name="fade">
      <div v-if="mobileMessageOpen" id="mobile-message" class="is-hidden-tablet">
        <MobileMessage /></div
    ></transition>
  </div>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { fabric } from 'fabric'
import fileSaver from 'file-saver'
import { design } from '@/utils/system'

export default {
  name: 'MainContainer',
  metaInfo: {
    title: 'Generate beautiful geometric design assets.',
    titleTemplate: 'PatternKid. %s',
    htmlAttrs: {
      lang: 'en'
    },
    meta: [
      { charset: 'utf-8' },
      {
        name: 'description',
        content:
          'Design beautiful geometric design assets - perfect for landing pages, slide decks, social posts and more! Get started for free.'
      },
      {
        name: 'og:url',
        content: 'https://patternkid.com'
      },
      {
        name: 'og:type',
        content: 'website'
      },
      {
        name: 'og:title',
        content: 'PatternKid.'
      },
      {
        name: 'og:description',
        content:
          'Design beautiful geometric design assets - perfect for landing pages, slide decks, social posts and more! Get started for free.'
      },
      {
        name: 'og:image',
        content: 'https://pattern-kid-main.s3.eu-west-1.amazonaws.com/assets/hero/10.png'
      },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' }
    ]
  },
  components: {
    Sidebar: () => import('@/components/workbench/WorkbenchSidebar.vue'),
    Loadbar: () => import('@/components/workbench/WorkbenchLoadbar.vue'),
    PatternContainer: () => import('@/components/pattern/PatternContainer.vue'),
    PatternLoading: () => import('@/components/workbench/WorkbenchPatternLoading.vue'),
    Navbar: () => import('@/components/navbar/Navbar.vue'),
    Controls: () => import('@/components/workbench/WorkbenchControls.vue'),
    FooterControls: () => import('@/components/workbench/WorkbenchFooterControls.vue'),
    ZoomBar: () => import('@/components/workbench/WorkbenchZoomBar.vue'),
    FadeButton: () => import('@/components/form/FormFadeButton.vue'),
    MobileMessage: () => import('@/components/workbench/WorkbenchMobileMessage.vue')
  },
  created() {
    window.addEventListener('keydown', this.key)
  },
  mounted() {
    window.fabric = fabric

    const authPrompt = this.authPrompt
    if (window.google) authPrompt()
    else {
      window.onload = function () {
        authPrompt()
      }
    }

    this.setPatternInProgress(false)
  },
  destroyed() {
    window.removeEventListener('keydown', this.key)
  },
  computed: {
    ...mapGetters(['modal', 'isMobile', 'isTablet']),
    ...mapGetters('auth', ['isSignedIn']),
    ...mapGetters('workbench', [
      'keysActive',
      'selectedTool',
      'sidebarOpen',
      'displayOpen',
      'mobileMessageOpen'
    ]),
    sidebarStyle() {
      return this.sidebarOpen ? 'left: 0' : 'left: -320px'
    },
    settingsStyle() {
      return this.sidebarOpen ? 'left: 320px' : 'left: 0px'
    },
    loadbarStyle() {
      return this.loadbarOpen ? 'right: 0' : 'right: -280px'
    },
    patternContainerStyle() {
      return this.sidebarOpen ? 'left: 320px' : 'left: 0'
    },
    googleEnabled() {
      return !!process.env.VUE_APP_ENABLE_GOOGLE
    }
  },
  methods: {
    ...mapMutations('workbench', [
      'toggleSidebar',
      'randomScheme',
      'setSelectedTool',
      'setPatternInProgress'
    ]),
    ...mapActions('auth', ['googleHandler']),
    authPrompt() {
      if (!this.isSignedIn && this.googleEnabled) {
        window.google.accounts.id.initialize({
          client_id: '162935689942-bjpuogu4qa6cprro5qeuv449ro1r0cbg.apps.googleusercontent.com',
          callback: this.googleHandler
        })
        window.google.accounts.id.prompt()
      }
    },
    key(event) {
      if (this.modal || !this.keysActive) return

      if (event.keyCode == 32) design()

      if (!event.shiftKey) return

      if (event.keyCode == 67) this.randomScheme()
      if (event.keyCode == 68) this.setSelectedTool({ type: 'dropper' })
      if (event.keyCode == 69) this.setSelectedTool({ type: 'eraser' })
      if (event.keyCode == 83) this.setSelectedTool({ type: 'select' })
      if (event.keyCode == 70) this.setSelectedTool({ type: 'paintfill' })
      if (event.keyCode == 74) this.saveData()
      if (event.keyCode == 88) this.$eventHub.$emit('rotateShape')
      if (event.keyCode == 8) this.$eventHub.$emit('deleteShape')
    },
    saveData() {
      const pattern = window.canvas.toJSON([
        'selectable',
        'hasBorders',
        '_controlsVisibility',
        '._element',
        'strokeWidth',
        'clipPath',
        'meta',
        'pk_fill',
        'pk_shape',
        'pk_padding',
        'pk_opacity',
        'pk_type'
      ])
      const blob = new Blob([JSON.stringify(pattern)], { type: 'text/plain;charset=utf-8' })
      fileSaver.saveAs(blob, 'data.json')
    }
  }
}
</script>

<style scoped>
#navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 57px;
}
#pattern-container {
  position: fixed;
  top: 106px;
  bottom: 57px;
  right: 0;
  left: 0;
}

#controls {
  position: fixed;
  top: 57px;
  height: 49px;
  right: 0;
  transition: left 0.3s linear;
}

#footer-controls {
  position: fixed;
  bottom: 0;
  height: 57px;
  left: 0;
  right: 0;
  transition: left 0.3s linear;
}

#sidebar {
  position: fixed;
  top: 57px;
  bottom: 57px;
  left: 0;
  width: 320px;
  z-index: 1;
  transition: left 0.3s linear;
}

#loadbar {
  position: fixed;
  top: 106px;
  bottom: 0;
  right: 0;
  width: 280px;
  z-index: 3;
  transition: right 0.3s linear;
  background: white;
}

#sidebar-button {
  position: fixed !important;
  left: 8px;
  bottom: 8px;
  z-index: 2;
}

#settings-button {
  position: fixed !important;
  top: 114px;
  z-index: 2;
  transition: left 0.3s linear;
}

#mobile-message {
  position: fixed !important;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 3;
  background: white;
  border-top: 1px solid whitesmoke;
}

.workbench-buttons {
  position: fixed !important;
  left: calc(50% - 80px);
  bottom: 16px;
  z-index: 2;
}
</style>
