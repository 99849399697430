<template>
  <div id="magic-page">
    <div id="magic-page-content" class="has-text-centered">
      <div v-if="status === 'loading'">
        <img id="logo-image" src="@/assets/imgs/logo.png" alt="PatternKid Logo" />
      </div>
      <transition name="fadein">
        <div v-if="status === 'used-token'">
          <div class="title">This link has been used.</div>
          <div class="subtitle is-padded-v-24">
            Don't worry, we'll just send you a new one if you like?
          </div>
          <div class="columns is-centered">
            <div class="column is-narrow">
              <div class="buttons">
                <div @click="backClicked" class="button is-white">Back to workbench</div>
                <div @click="sendClicked" class="button is-light">Send again</div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import { mapMutations, mapActions } from 'vuex'
export default {
  name: 'Magic',
  data: function () {
    return {
      status: 'loading'
    }
  },
  async mounted() {
    try {
      this.setTokenRequested(false)
      await this.verifyToken(this.$route.query)
      this.$router.push('/workbench')
    } catch (err) {
      if (err.response.data.message === 'token has been used') {
        this.status = 'used-token'
      }
    }
  },
  methods: {
    ...mapMutations('auth', ['setTokenRequested']),
    ...mapMutations(['setModal']),
    ...mapActions('auth', ['verifyToken']),
    backClicked() {
      this.$router.push('/workbench')
    },
    sendClicked() {
      this.setModal({ component: 'Auth' })
    }
  }
}
</script>
<style>
#magic-page {
  display: table;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

#magic-page-content {
  position: fixed;
  top: 48%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#logo-image {
  height: 80px;
  width: 80px;
  animation: rotation 4s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
</style>
