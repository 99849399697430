import store from '@/store'
import { create } from 'pattern'
import { api } from '@/utils/axios'
import eventHub from '@/utils/eventHub'
import Actions from '@/components/pattern/actions'

export const PATTERN_PARAMS = [
  'selectable',
  'hasBorders',
  '_controlsVisibility',
  '._element',
  'strokeWidth',
  'clipPath',
  'minScaleLimit',
  'lockScalingFlip',
  'lastHeight',
  'meta',
  'pk_top',
  'pk_left',
  'pk_fill',
  'pk_shape',
  'pk_padding',
  'pk_opacity',
  'pk_rotation',
  'pk_type',
  'pk_image',
  'pk_texture'
]

export const getDesignConfig = () => {
  const selectedBlocks = store.getters['workbench/selectedBlocks']
  const blocks = Object.keys(selectedBlocks).filter((key) => !!selectedBlocks[key])

  return {
    width: store.getters['workbench/width'],
    height: store.getters['workbench/height'],
    border: store.getters['workbench/border'],
    padding: store.getters['workbench/padding'],
    opacity: store.getters['workbench/opacity'] / 10,
    backgroundOpacity: store.getters['workbench/backgroundOpacity'] / 10,
    colours: store.getters['workbench/colours'],
    background: store.getters['workbench/background'],
    gradients: store.getters['workbench/gradients'],
    density: store.getters['workbench/density'],
    designView: store.getters['workbench/designView'],
    backgroundView: store.getters['workbench/backgroundView'],
    gradientsView: store.getters['workbench/gradientsView'],
    paddingView: store.getters['workbench/paddingView'] === 'on',
    templates: store.getters['workbench/selectedTemplates'],
    blocks,
    shapes: store.getters['workbench/selectedShapeNames'],
    images: store.getters['workbench/selectedImages'],
    textures: store.getters['workbench/selectedTextures'],
    grid: store.getters['workbench/backgroundGrid'] === 'on',
    selectable: false,
    shadow: store.getters['workbench/shadow'],
    shadowType: store.getters['workbench/shadowType'],
    text: store.getters['workbench/text'],
    font: store.getters['workbench/selectedFont']
  }
}

export const compressImages = () => {
  window.canvas.forEachObject(async (object) => {
    if (object.pk_image) {
      await Actions.compressImage(object)
    }
  })
}

export const design = () => {
  if (store.getters['workbench/patternInProgress']) return
  store.commit('workbench/setPatternInProgress', true)

  console.time('create')
  console.time('load')

  setTimeout(async () => {
    window.canvas.removeForegroundObjects()

    const config = getDesignConfig()
    const { pattern, background } = await create(config)

    console.timeEnd('create')

    await window.canvas.loadFromJSON(pattern)

    console.timeEnd('load')

    store.commit('workbench/setPatternBackground', background)
    store.commit('workbench/setPatternInProgress', false)
  }, 20)
}

export const reloadPage = async () => {
  const pattern = createPattern()
  await window.canvas.loadFromJSON(pattern)
}

const createBase64 = () => {
  return window.canvas
    .toDataURL({
      format: 'png'
    })
    .replace('data:image/png;base64,', '')
}

const createPattern = () => {
  return window.canvas.toJSON(PATTERN_PARAMS)
}

const createMeta = () => {
  const dimensions = store.getters['workbench/dimensions']
  const border = store.getters['workbench/border']
  const colours = store.getters['workbench/colours']
  const gradients = store.getters['workbench/gradients']
  const outline = store.getters['workbench/outline']
  const opacity = store.getters['workbench/opacity']
  const backgroundOpacity = store.getters['workbench/backgroundOpacity']
  const text = store.getters['workbench/text']
  const selectedFont = store.getters['workbench/selectedFont']

  return {
    dimensions,
    border,
    colours,
    gradients,
    outline,
    opacity,
    backgroundOpacity,
    text,
    selectedFont
  }
}

export const savePattern = async () => {
  await api.post('/api/pattern/save', {
    base64: createBase64(),
    pattern: createPattern(),
    meta: createMeta()
  })
}

export const loadPattern = async (pattern, meta = null) => {
  window.canvas.loadFromJSON(pattern, async () => {
    if (meta) {
      if (typeof meta.border === 'number') store.commit('workbench/setBorder', meta.border)
      if (typeof meta.outline === 'number') store.commit('workbench/setOutline', meta.outline)
      if (typeof meta.opacity === 'number') store.commit('workbench/setOpacity', meta.opacity)
      if (typeof meta.backgroundOpacity === 'number')
        store.commit('workbench/setBackgroundOpacity', meta.backgroundOpacity)
      if (meta.colours) store.commit('workbench/setColours', meta.colours)
      if (meta.gradients) store.commit('workbench/setGradients', meta.gradients)
      if (meta.text) store.commit('workbench/setText', meta.text)
      if (meta.selectedFont) store.commit('workbench/setSelectedFont', meta.selectedFont)

      await store.dispatch('workbench/setDimensions', meta.dimensions)
      eventHub.$emit('setDimensions', meta.dimensions)
    }

    window.canvas.resizeBackground()
    window.canvas._historySaveAction()
    window.canvas.requestRenderAll()
  })
}

export const createLink = async () => {
  const { data } = await api.post('/api/share', {
    base64: createBase64(),
    pattern: createPattern(),
    meta: createMeta()
  })
  return data.link
}

export const sendEmailLink = async (email) => {
  await api.post('/api/share/email', {
    email,
    base64: createBase64(),
    pattern: createPattern(),
    meta: createMeta()
  })
}

export const addSuggestion = async (suggestion) => {
  return await api.post('/api/marketing/suggestion', {
    suggestion
  })
}

export const addNewsletterEmail = async (email) => {
  return await api.post('/api/marketing/newsletter', {
    email
  })
}
