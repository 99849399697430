<template>
  <div>
    <div id="pricing-container">
      <div class="is-hidden-mobile" id="left-img"></div>
      <div class="is-hidden-mobile" id="right-img"></div>
      <Navbar />
      <div class="container">
        <div class="section">
          <LayoutHeader
            title="Pricing"
            subtitle="Get started for free by exploring the pattern workbench or create a pro account to access all our features."
          />
        </div>
        <div class="has-text-centered">
          <Toggle
            :options="['monthly', 'yearly', 'forever!']"
            :action="setPeriod"
            :activeOption="period"
            scheme="black"
          />
        </div>
        <div class="section">
          <div class="columns is-centered">
            <div class="column is-4-widescreen is-5-desktop is-6-tablet">
              <PricingOption
                title="PatternKid Normalo"
                subtitle="Create geometric assets with all our core pattern building features. Beloved by lighter content creators."
                :price="{ price: 'Free', period: '' }"
                :action="() => $router.push('/workbench')"
                actionMessage="Get started for free"
                :features="['Workbench', '3 pattern files', '3 saved palettes', '1 collection']"
              />
            </div>
            <div class="column is-4-widescreen is-5-desktop is-6-tablet">
              <PricingOption
                :simple="simple"
                title="PatternKid Pro"
                subtitle="Create and save unlimited geometric patterns. Beloved by pros and serious content creators."
                :price="proPrice"
                :action="() => $router.push('/subscribe')"
                actionMessage="Get started"
                :features="[
                  'Unlimited high definition downloads',
                  'Unlimited pattern files',
                  'Unlimited saved palettes',
                  'Unlimited collections',
                  'All templates',
                  'All textures',
                  'Early access to new features'
                ]"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <PricingFaqs />
    <Footer />
  </div>
</template>
<script>
import settings from '@/utils/settings'

export default {
  name: 'Pricing',
  components: {
    Navbar: () => import('@/components/landing/LandingNavbar.vue'),
    LayoutHeader: () => import('@/components/layout/LayoutHeader.vue'),
    Toggle: () => import('@/components/form/FormToggle.vue'),
    PricingFaqs: () => import('@/components/pricing/PricingFaqs.vue'),
    PricingOption: () => import('@/components/pricing/PricingOption.vue'),
    Footer: () => import('@/components/landing/LandingFooter.vue')
  },
  data: function () {
    return { period: 'monthly' }
  },
  computed: {
    proPrice() {
      if (this.period === 'monthly') {
        return { price: `$${settings.monthlySubscriptionPrice}`, period: 'per month' }
      } else if (this.period === 'yearly') {
        return { price: `$${settings.yearlySubscriptionPrice}`, period: 'per year' }
      } else {
        return { price: `$${settings.lifetimeSubscriptionPrice}`, period: 'forever!' }
      }
    }
  },
  methods: {
    setPeriod(period) {
      this.period = period
    }
  }
}
</script>
<style scoped>
#pricing-container {
  position: relative;
}

#left-img {
  position: absolute;
  top: 47px;
  left: 0;
  bottom: 0;
  right: 50%;
  z-index: -1;
  background-image: url('https://pattern-kid-main.s3.eu-west-1.amazonaws.com/assets/pricing-left.png');
  background-position: top right;
  background-repeat: no-repeat;
  background-size: cover;
}

#right-img {
  position: absolute;
  top: 47px;
  left: 50%;
  bottom: 0;
  right: 0;
  z-index: -1;
  background-image: url('https://pattern-kid-main.s3.eu-west-1.amazonaws.com/assets/pricing-right.png');
  background-position: top left;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
