import { render, staticRenderFns } from "./Collection.vue?vue&type=template&id=7fa3b537&scoped=true"
import script from "./Collection.vue?vue&type=script&lang=js"
export * from "./Collection.vue?vue&type=script&lang=js"
import style0 from "./Collection.vue?vue&type=style&index=0&id=7fa3b537&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7fa3b537",
  null
  
)

export default component.exports