import { textures } from 'pattern'
import settings from '@/utils/settings'

export const addTexture = async (object, texture) => {
  return new Promise((resolve) => {
    texture = texture || object.pk_texture
    // const pk_svg = textures.buildPkSvg(texture.name, object.pk_fill)
    const pk_svg = `<svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.dev/svgjs" viewBox="0 0 800 800" width="200px" height="200px">
<defs>     
</defs>
<g stroke-width="80" stroke="black" fill="none" clip-path="url(#clip)" >
  <clipPath id="clip" clipPathUnits="objectBoundingBox" >
    <path stroke-width="0" fill-rule="nonzero" d="M1,0 A1,1 0 0 1 0,1 L0,0 Z" />
  </clipPath>
<line x1="0" y1="0" x2="800" y2="800" stroke-linecap="square" ></line>
<line x1="200" y1="0" x2="800" y2="600" stroke-linecap="square" ></line>
<line x1="400" y1="0" x2="800" y2="400" stroke-linecap="square" ></line>
<line x1="600" y1="0" x2="800" y2="200" stroke-linecap="square" ></line>
<line x1="798" y1="-2" x2="802" y2="2" stroke-linecap="square" ></line>
<line x1="0" y1="200" x2="600" y2="800" stroke-linecap="square" ></line>
<line x1="0" y1="400" x2="400" y2="800" stroke-linecap="square" ></line>
<line x1="0" y1="600" x2="200" y2="800" stroke-linecap="square" ></line>
<line x1="-2" y1="798" x2="2" y2="802" stroke-linecap="square" ></line>
</g></svg>
`

    window.fabric.loadSVGFromString(pk_svg, (objects, options) => {
      const textureObject = window.fabric.util.groupSVGElements(objects, options)
      canvas.add(textureObject).renderAll()

      // textureObject.rotate(360 - object.pk_rotation)

      // const patternSourceCanvas = new window.fabric.StaticCanvas(null, {
      //   enableRetinaScaling: false
      // })
      // patternSourceCanvas.add(textureObject)
      // patternSourceCanvas.setDimensions({ width: settings.resolution, height: settings.resolution })
      // const source = patternSourceCanvas.getElement()
      // const patternScale = 1 / object.scaleX

      // const pattern = new window.fabric.Pattern({
      //   source,
      //   repeat: 'repeat',
      //   patternTransform: [patternScale, 0, 0, patternScale, 0, 0]
      // })

      // const newObject = window.fabric.util.object.clone(object._objects[1])
      // newObject.set('fill', pattern)
      // object._objects[1] = textureObject

      object.pk_texture = texture
      resolve()
    })
  })
}
