const setScreen = () => {
  const width = window.innerWidth
  if (width < 769) return 'mobile'
  if (width < 1024) return 'tablet'
  return 'desktop'
}

const settings = {
  resolution: 200,
  screen: setScreen(),
  initBorder: 100,
  initDimension: 6,
  initDimensionMobile: 4,
  maxDimension: 14,
  maxBorder: 200,
  maxPadding: 180,
  monthlySubscriptionPrice: 10,
  yearlySubscriptionPrice: 60,
  lifetimeSubscriptionPrice: 100
}

export default settings
