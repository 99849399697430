import settings from '@/utils/settings'
import { shapes, templates } from 'pattern'
import { randomScheme } from '@/services/colours'
import { randomGradient } from '@/services/gradients'
import { getRandomArray } from '@/services/random'

const getDefaultState = () => {
  return {
    patternInProgress: false,
    keysActive: true,
    width: settings.screen === 'desktop' ? settings.initDimension : settings.initDimensionMobile,
    height: settings.screen === 'desktop' ? settings.initDimension : settings.initDimensionMobile,
    border: settings.initBorder,
    zoom: 100,
    snapshot: null,
    selectedTool: { type: 'select' },
    colours: ['#99b898', '#fecea8', '#ff847c', '#e84a5f', '#2a363b'],
    gradients: [
      {
        colours: ['#e84a5f', '#fecea8'],
        percentages: [1, 100],
        rotation: 90,
        stringValue: 'linear-gradient(90deg ,#e84a5f 1%, #fecea8 100%)',
        arrayValue: [
          { percentage: 1, colour: '#e84a5f' },
          { percentage: 100, colour: '#fecea8' }
        ]
      },
      {
        colours: ['#fecea8', '#99b898'],
        percentages: [1, 100],
        rotation: 90,
        stringValue: 'linear-gradient(90deg ,#fecea8 1%, #99b898 100%)',
        arrayValue: [
          { percentage: 1, colour: '#fecea8' },
          { percentage: 100, colour: '#99b898' }
        ]
      }
    ],
    selectedColour: '#99b898',
    background: 'transparent',
    padding: 0,
    opacity: 10,
    backgroundOpacity: 10,
    outline: 0,
    shadow: 0,
    shadowType: 'black',
    density: 10,
    text: '',
    fontAlign: 'left',
    selectedShapes: [shapes.userList[0], shapes.userList[1]],
    selectedBlocks: { block112: true, block2: true, block11: true, block110: true, block12: true },
    selectedTemplates: [...templates.getFreeTemplates()],
    selectedImages: [],
    selectedTextures: [],
    selectedFont: {
      files: {
        regular:
          'http://fonts.gstatic.com/s/robotomono/v22/L0xuDF4xlVMF-BfR8bXMIhJHg45mwgGEFl0_3vqPQ--5Ip2sSQ.ttf'
      },
      family: 'Roboto Mono',
      id: 'roboto-mono'
    },
    designView: 'templates',
    backgroundView: 'random',
    gradientsView: 'off',
    paddingView: 'off',
    backgroundGrid: 'on',
    designTab: 'elements',
    mouseDown: false,
    sidebarOpen: false,
    displayOpen: 'command',
    mobileMessageOpen: true,
    colourPicker: { event: null, colour: null }
  }
}

const store = {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    patternInProgress: (state) => state.patternInProgress,
    keysActive: (state) => state.keysActive,
    width: (state) => state.width,
    height: (state) => state.height,
    border: (state) => state.border,
    dimensions: (state) => {
      const { width, height } = state
      return { width, height }
    },
    canvasDimensions: (state) => {
      return {
        width: state.zoom * state.width + (state.border * 2 * state.zoom) / settings.resolution,
        height: state.zoom * state.height + (state.border * 2 * state.zoom) / settings.resolution
      }
    },
    zoom: (state) => state.zoom,
    zoomFactor: (state) => settings.resolution / state.zoom,
    snapshot: (state) => state.snapshot,
    selectedTool: (state) => state.selectedTool,
    colours: (state) => state.colours,
    gradients: (state) => state.gradients,
    selectedColour: (state) => state.selectedColour,
    availableColours: (state) => {
      return state.colours.filter((colour) => colour !== state.background)
    },
    background: (state) => state.background,
    padding: (state) => state.padding,
    opacity: (state) => state.opacity,
    backgroundOpacity: (state) => state.backgroundOpacity,
    outline: (state) => state.outline,
    shadow: (state) => state.shadow,
    shadowType: (state) => state.shadowType,
    density: (state) => state.density,
    text: (state) => state.text,
    fontAlign: (state) => state.fontAlign,
    selectedShapes: (state) => state.selectedShapes,
    selectedShapeNames: (state) => state.selectedShapes.map((shape) => shape.name),
    selectedBlocks: (state) => state.selectedBlocks,
    selectedTemplates: (state) => state.selectedTemplates,
    selectedImages: (state) => state.selectedImages,
    selectedTextures: (state) => state.selectedTextures,
    selectedFont: (state) => state.selectedFont,
    designView: (state) => state.designView,
    backgroundView: (state) => state.backgroundView,
    gradientsView: (state) => state.gradientsView,
    paddingView: (state) => state.paddingView,
    backgroundGrid: (state) => state.backgroundGrid,
    designTab: (state) => state.designTab,
    mouseDown: (state) => state.mouseDown,
    sidebarOpen: (state) => state.sidebarOpen,
    displayOpen: (state) => state.displayOpen,
    mobileMessageOpen: (state) => state.mobileMessageOpen,
    colourPicker: (state) => state.colourPicker
  },
  mutations: {
    resetState(state) {
      Object.assign(state, getDefaultState())
    },
    resetSignOut(state) {
      const defaultState = getDefaultState()
      state.selectedTool = defaultState.selectedTool
      state.selectedImages = defaultState.selectedImages
      state.selectedTextures = defaultState.selectedTextures
      state.selectedTemplates = defaultState.selectedTemplates
      state.selectedBlocks = defaultState.selectedBlocks
    },
    setPatternInProgress(state, inProgress) {
      state.patternInProgress = inProgress
    },
    setKeysActive(state, active) {
      state.keysActive = active
    },
    setWidth(state, width) {
      state.width = width
    },
    setHeight(state, height) {
      state.height = height
    },
    setBorder(state, border) {
      state.border = border
    },
    setZoom(state, zoom) {
      state.zoom = zoom
    },
    setSnapshot(state, snapshot) {
      state.snapshot = snapshot
    },
    setSelectedTool(state, tool) {
      state.selectedTool = tool
    },
    rotateSelectedShape(state) {
      if (state.selectedTool.type !== 'shape') return
      if (!state.selectedTool.rotation) {
        state.selectedTool.rotation = 90
      } else if (state.selectedTool.rotation === 270) {
        state.selectedTool.rotation = 0
      } else {
        state.selectedTool.rotation += 90
      }

      state.selectedTool = { ...state.selectedTool }
    },
    setSelectedColour(state, colour) {
      state.selectedColour = colour
    },
    setColours(state, colours) {
      state.colours = colours
    },
    addColour(state) {
      state.colours.push('#fff')
    },
    deleteColour(state, colour) {
      if (state.colours.length === 1) return

      const index = state.colours.indexOf(colour)
      if (index > -1) {
        state.colours.splice(index, 1)
      }
    },
    updateColour(state, { colour, index }) {
      const newColours = [...state.colours]
      newColours[index] = '#' + colour
      state.colours = newColours
    },
    setGradients(state, gradients) {
      state.gradients = gradients
    },
    addGradient(state, gradient) {
      state.gradients.push(gradient)
    },
    updateGradient(state, { index, gradient }) {
      let gradients = [...state.gradients]
      gradients[index] = gradient
      state.gradients = gradients
    },
    deleteGradient(state, index) {
      state.gradients.splice(index, 1)
    },
    randomScheme(state) {
      state.colours = randomScheme()
      state.gradients = []
      for (var i = 0; i < 2; i++) {
        randomGradient()
      }
      state.selectedColour = state.colours[0]
    },
    setScheme(state, scheme) {
      state.colours = scheme
      state.gradients = []
      for (var i = 0; i < 2; i++) {
        randomGradient()
      }
      state.selectedColour = state.colours[0]
    },
    setBackground(state, background) {
      if (state.background === background) state.background = 'transparent'
      else state.background = background
    },
    setPatternBackground(state, background) {
      state.background = background
    },
    setPadding(state, padding) {
      state.padding = padding
    },
    setOpacity(state, opacity) {
      state.opacity = opacity
    },
    setBackgroundOpacity(state, backgroundOpacity) {
      state.backgroundOpacity = backgroundOpacity
    },
    setShadow(state, shadow) {
      state.shadow = shadow
    },
    setShadowType(state, type) {
      state.shadowType = type
    },
    setOutline(state, outline) {
      state.outline = outline
    },
    setDensity(state, density) {
      state.density = density
    },
    setText(state, text) {
      state.text = text
    },
    setFontAlign(state, align) {
      state.fontAlign = align
    },
    toggleSelectedBlock(state, block) {
      const isLastBlock =
        Object.keys(state.selectedBlocks).filter((key) => !!state.selectedBlocks[key]).length > 1
      if (isLastBlock || !state.selectedBlocks[block]) {
        state.selectedBlocks[block] = !state.selectedBlocks[block]
      }
    },
    addSelectedShape(state, shape) {
      const index = state.selectedShapes.findIndex((selectedShape) => {
        return selectedShape.name === shape.name
      })
      if (index !== -1 && state.selectedShapes.length > 1) state.selectedShapes.splice(index, 1)
      else if (index === -1) state.selectedShapes.push(shape)
    },
    addSelectedTemplate(state, template) {
      const index = state.selectedTemplates.findIndex((selectedTemplate) => {
        return selectedTemplate === template
      })
      if (index !== -1 && state.selectedTemplates.length > 1)
        state.selectedTemplates.splice(index, 1)
      else if (index === -1) state.selectedTemplates.push(template)
    },
    addSelectedImage(state, image) {
      const index = state.selectedImages.findIndex((selectedImage) => {
        return selectedImage.uuid === image.uuid
      })
      if (index !== -1) state.selectedImages.splice(index, 1)
      else state.selectedImages.push(image)
    },
    removeSelectedImage(state, image) {
      const index = state.selectedImages.findIndex((selectedImage) => {
        return selectedImage.uuid === image.uuid
      })
      if (index !== -1) state.selectedImages.splice(index, 1)
    },
    addSelectedTexture(state, texture) {
      const index = state.selectedTextures.findIndex((selectedTexture) => {
        return selectedTexture.name === texture.name
      })
      if (index !== -1) state.selectedTextures.splice(index, 1)
      else state.selectedTextures.push(texture)
    },
    setSelectedFont(state, font) {
      state.selectedFont = font
    },
    setDesignView(state, view) {
      state.designView = view
    },
    setBackgroundView(state, view) {
      state.backgroundView = view
    },
    setGradientsView(state, view) {
      state.gradientsView = view
    },
    setPaddingView(state, view) {
      state.paddingView = view
    },
    setBackgroundGrid(state, view) {
      state.backgroundGrid = view
    },
    setDesignTab(state, tab) {
      state.designTab = tab
    },
    setMouseDown(state, mouseDown) {
      state.mouseDown = mouseDown
    },
    toggleSidebar(state) {
      state.sidebarOpen = !state.sidebarOpen
    },
    setDisplayOpen(state, display) {
      if (state.displayOpen === display) state.displayOpen = null
      else state.displayOpen = display
    },
    setMobileMessageOpen(state, open) {
      state.mobileMessageOpen = open
    },
    toggleColourPicker(state, { colour, index }) {
      state.colourPicker = { colour, index }
    }
  },
  actions: {
    randomColourSet: ({ getters }, length) => {
      return getRandomArray(length, getters['availableColours'])
    },
    randomSelectedShape: ({ state }) => {
      return state.selectedShapes[Math.floor(Math.random() * state.selectedShapes.length)]
    },
    setDimensions: ({ commit }, { width, height }) => {
      commit('setWidth', width)
      commit('setHeight', height)
    }
  }
}

export default store
