<template>
  <div>
    <Navbar />
    <div class="container mt-6">
      <LayoutHeader title="Privacy Notice" subtitle="" />
    </div>
    <div class="container">
      <div class="section">
        <div v-html="privacy"></div>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import markdownit from 'markdown-it'
import markdownStyle from 'markdown-it-style'
import privacyMd from '../services/notices/privacy.md'

export default {
  name: 'Landing',
  components: {
    Navbar: () => import('@/components/landing/LandingNavbar.vue'),
    LayoutHeader: () => import('@/components/layout/LayoutHeader.vue'),
    Footer: () => import('@/components/landing/LandingFooter.vue')
  },
  computed: {
    privacy() {
      const md = markdownit()
      md.use(markdownStyle, {
        h1: 'font-size: 1.5rem;font-weight: 700;margin-bottom: 1rem;margin-top: 2rem;',
        h2: 'font-size: 1.2rem;font-weight: 700;margin-bottom: 1rem;margin-top: 2rem;',
        p: 'margin-bottom: 1rem;',
        a: 'color: red'
      })
      return md.render(privacyMd)
    }
  }
}
</script>
