import settings from '@/utils/settings'
import { getDataUrl, getCdnUrl, resizeDataUrl, rotateImage } from '@/utils/image'

export const compressImage = (target) => {
  target._objects[1]._objects[2].fill = 'black'
}

const getSrc = (image, dimension) => {
  const imageSizes = [400, 800, 1200]
  const size = imageSizes.filter((size) => dimension <= size)[0] || 2000

  return getCdnUrl(image, size)
}

export const addDataUrl = async (object, dataUrl, scaledDimension) => {
  // // const rotatedDataUrl = await rotateImage(dataUrl, object.pk_rotation)
  // const resizedDataUrl = await resizeDataUrl(dataUrl, scaledDimension, scaledDimension)
  return new Promise((resolve) => {
    window.fabric.Image.fromURL(dataUrl, (image) => {
      const patternSourceCanvas = new window.fabric.StaticCanvas(null, {
        enableRetinaScaling: false
      })
      image.scaleToHeight(scaledDimension)
      patternSourceCanvas.add(image)
      patternSourceCanvas.setDimensions({ width: scaledDimension, height: scaledDimension })
      const source = patternSourceCanvas.getElement()

      const pattern = new window.fabric.Pattern({
        source,
        repeat: 'no-repeat',
        patternTransform: [1 / object.scaleX, 0, 0, 1 / object.scaleX, 0, 0],
        offsetX: 0,
        offsetY: 0
      })

      const newObject = window.fabric.util.object.clone(object._objects[1]._objects[1])
      newObject.set('fill', pattern)
      object._objects[1]._objects[2] = newObject
      resolve()
    })
  })
}

export const addImage = async (object, image = null) => {
  const { scaleX: scale } = object
  const scaledDimension = settings.resolution * scale
  image = image || object.pk_image
  object.pk_image = image

  const src = getSrc(image, scaledDimension)
  const dataUrl = await getDataUrl(src)
  return await addDataUrl(object, dataUrl, scaledDimension)
}
