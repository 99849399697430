<template>
  <div>
    <Navbar />
    <Hero />
    <div class="section"><Inspiration /></div>
    <UseCase
      class="brutal-border-top"
      title="PatternKid for UI Design"
      text="Create instant, consistant brand assets without any design skills. Professional results without wasting time!"
      imgSrc="https://pattern-kid-main.s3.eu-west-1.amazonaws.com/assets/use-cases/ui-design.webp"
      background="has-background-link"
    />
    <UseCase
      class="brutal-border-top"
      title="PatternKid for Ecommerce"
      text="Liven up you product shots without the hastle. Use you brand colors and create collections in a moment."
      imgSrc="https://pattern-kid-main.s3.eu-west-1.amazonaws.com/assets/use-cases/ecommerce.webp"
      background="has-background-primary-light"
      :switchColumns="true"
    />
    <UseCase
      class="brutal-border-top"
      title="PatternKid for Social Media"
      text="Don't waste time using content design tools. Get instant results without the effort."
      imgSrc="https://pattern-kid-main.s3.eu-west-1.amazonaws.com/assets/use-cases/social-media.webp"
      background="has-background-info"
    />
    <Features />
    <div class="section"><Newsletter /></div>
    <Footer />
  </div>
</template>
<script>
export default {
  name: 'Landing',
  components: {
    Navbar: () => import('@/components/landing/LandingNavbar.vue'),
    Hero: () => import('@/components/landing/LandingHero.vue'),
    Features: () => import('@/components/landing/LandingFeatures.vue'),
    UseCase: () => import('@/components/landing/LandingUseCase.vue'),
    Inspiration: () => import('@/components/landing/LandingInspiration.vue'),
    Newsletter: () => import('@/components/landing/LandingNewsletter.vue'),
    Footer: () => import('@/components/landing/LandingFooter.vue')
  }
}
</script>
<style>
.glide {
  max-width: 100%;
}
</style>
