import axios from 'axios'
import { Buffer } from 'buffer'

export const getCdnFallback = (image) => {
  return `${process.env.VUE_APP_CDN_URL}/uploaded-images/${image.uuid}.${image.type}`
}

export const getCdnUrl = (image, size) => {
  return `${process.env.VUE_APP_CDN_URL}/development/images/${size}/${image.uuid}.${image.type}`
}

export const base64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data)
  const byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)

    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }

  const blob = new Blob(byteArrays, { type: contentType })
  return blob
}

const getBase64 = async (url) => {
  const { data } = await axios.get(url, {
    responseType: 'arraybuffer'
  })
  return Buffer.from(data, 'binary').toString('base64')
}

export const getDataUrl = async (src) => {
  const base64 = await getBase64(src)
  const fileType = src.split('.').pop()
  return `data:image/${fileType};base64,${base64}`
}

export const resizeDataUrl = (url, width, height, type = 'png') => {
  return new Promise((resolve, reject) => {
    try {
      const canvas = document.createElement('canvas')
      canvas.width = width
      canvas.height = height
      const ctx = canvas.getContext('2d')
      const sourceImage = new Image()

      sourceImage.onload = function () {
        if (sourceImage.width < width) resolve(url)

        ctx.drawImage(sourceImage, 0, 0, width, height)
        resolve(canvas.toDataURL(`image/${type}`))
      }

      sourceImage.src = url
    } catch (err) {
      reject(err)
    }
  })
}

export const rotateImage = (url, angle) => {
  return new Promise((resolve, reject) => {
    try {
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')
      const sourceImage = new Image()

      sourceImage.onload = function () {
        canvas.width = angle % 180 === 0 ? sourceImage.width : sourceImage.height
        canvas.height = angle % 180 === 0 ? sourceImage.height : sourceImage.width

        ctx.translate(canvas.width / 2, canvas.height / 2)
        ctx.rotate((angle * Math.PI) / 180)
        ctx.drawImage(sourceImage, sourceImage.width / -2, sourceImage.height / -2)

        resolve(canvas.toDataURL())
      }

      sourceImage.src = url
    } catch (err) {
      reject(err)
    }
  })
}
