<template>
  <div>
    <div id="collection-container" class="container is-vscrollable">
      <transition name="fadein">
        <div v-if="collection" class="section">
          <div class="columns is-centered is-vcentered">
            <div class="column is-narrow has-text-centered">
              <div
                id="collection-name"
                class="title is-2 is-padded-h-8"
                :style="nameStyle"
                :contentEditable="editing"
              >
                <span> {{ collection.name }} </span>
              </div>
              <div class="subtitle has-text-grey-light">
                <span>
                  {{ collection.patterns.length }} pattern{{
                    collection.patterns.length > 1 ? 's' : ''
                  }}
                </span>
              </div>
              <div v-if="ownedByUser" class="level">
                <div class="level-item">
                  <div class="buttons">
                    <div @click="editing = !editing" class="button is-white" :class="editClass">
                      <span class="icon is-small">
                        <i class="fas fa-pen"></i>
                      </span>
                      <span>Edit</span>
                    </div>
                    <div @click="copyClicked" class="button is-white">
                      <span class="icon is-small">
                        <i class="fas fa-link"></i>
                      </span>
                      <span>Copy</span>
                    </div>
                    <div @click="deleteClicked" class="button is-white">
                      <span class="icon is-small">
                        <i class="fas fa-trash-alt"></i>
                      </span>
                      <span>Delete</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
      <Wall
        v-if="collection"
        class="is-padded-bottom-48 is-padded-h-12"
        :patterns="collection.patterns"
        :buttons="[{ icon: 'fas fa-trash-alt', action: deletePatternClicked }]"
        :showButtons="editing"
      />
    </div>
    <div id="collection-navbar">
      <Navbar />
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import copy from 'copy-to-clipboard'
import { api } from '@/utils/axios'
export default {
  name: 'Collection',
  components: {
    Navbar: () => import('@/components/navbar/Navbar.vue'),
    Wall: () => import('@/components/wall/Wall.vue')
  },
  data: function () {
    return {
      collection: null,
      editing: false
    }
  },
  async mounted() {
    const { data } = await api.get(`/api/collection/${this.$route.query.uuid}`)
    this.collection = data
  },
  watch: {
    async editing(newValue) {
      if (newValue) return
      const name = document.getElementById('collection-name').innerText
      if (name !== this.collection.name) {
        await this.saveChanges(name)
      }
    }
  },
  computed: {
    ...mapGetters('auth', ['user']),
    editClass() {
      return this.editing ? 'is-primary is-light' : ''
    },
    thumbnailClass() {
      return this.editing ? '' : 'is-clickable'
    },
    nameStyle() {
      return this.editing ? 'outline: 1px solid hsl(0deg, 0%, 71%)' : 'outline: none'
    },
    ownedByUser() {
      if (!this.user) return false
      return this.user.uuid === this.collection.userUuid
    }
  },
  methods: {
    ...mapMutations(['setModal']),
    ...mapMutations('profile', ['setCollections']),
    copyClicked() {
      try {
        copy(`${process.env.VUE_APP_URL}/collection?uuid=${this.$route.query.uuid}`)
        this.$notify({
          group: 'success',
          title: 'Link copied to clipboard!'
        })
      } catch {
        this.$notify({
          group: 'danger',
          title: 'Failed to make link.'
        })
      }
    },
    async deletePatternClicked(uuid) {
      this.setModal({
        component: 'Confirmation',
        data: {
          message: 'remove this pattern',
          button: 'Remove',
          action: async () => {
            const { data } = await api.delete(
              `/api/collection/pattern?patternUuid=${uuid}&collectionUuid=${this.collection.uuid}`
            )
            this.collection = data
          }
        }
      })
    },
    async deleteClicked() {
      this.setModal({
        component: 'Confirmation',
        data: {
          message: 'delete this collection',
          button: 'Delete',
          action: async () => {
            const { data } = await api.delete(`/api/collection?uuid=${this.collection.uuid}`)
            this.setCollections(data)
            this.$router.push('/profile')
          }
        }
      })
    },
    async saveChanges(name) {
      const { data } = await api.post('/api/collection/update', {
        fields: { name },
        uuid: this.collection.uuid
      })
      this.collection = data
    }
  }
}
</script>
<style scoped>
#collection-navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 57px;
}

#collection-container {
  position: fixed;
  top: 57px;
  left: 0;
  right: 0;
  bottom: 0;
}

*[contenteditable='true'] {
  outline: none;
  border-radius: 4px;
}
*[contenteditable='true']:focus-visible {
  outline: 1px solid hsl(0deg, 0%, 71%);
  outline-color: #b52d9e !important;
  box-shadow: 0 0 0 8px rgba(181, 45, 158, 0.25) !important;
}
</style>
