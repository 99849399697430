<template>
  <div>
    <div id="settings-container" class="is-vscrollable">
      <div class="container">
        <div class="is-padded-v-24">
          <div class="columns is-mobile">
            <div class="column"></div>
            <div class="column is-narrow">
              <div
                @click="workbenchClicked"
                class="button is-primary has-text-weight-semibold has-margin-left-12 has-margin-right-12"
              >
                <span class="icon">
                  <i class="fas fa-check-circle"></i>
                </span>
                <span>Save Changes</span>
              </div>
            </div>
          </div>
        </div>
        <DesignSettings />
      </div>
    </div>
    <div id="settings-navbar">
      <Navbar />
    </div>
  </div>
</template>
<script>
export default {
  name: 'Settings',
  components: {
    Navbar: () => import('@/components/navbar/Navbar.vue'),
    DesignSettings: () => import('@/components/design/DesignSettings.vue')
  },
  methods: {
    workbenchClicked() {
      this.$router.push('/workbench')
    }
  }
}
</script>
<style>
#settings-navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 57px;
}

#settings-container {
  position: fixed;
  top: 57px;
  bottom: 0;
  left: 0;
  right: 0;
}
</style>
