import { clickShape } from './click'
import { selectObject } from './select'
import { addShape } from './shape'
import { addImage, compressImage } from './image'
import { addText } from './text'
import { addTexture } from './texture'
import { eraseShape } from './erase'
import { dropShape } from './drop'

export default {
  clickShape,
  selectObject,
  addShape,
  addImage,
  compressImage,
  addText,
  addTexture,
  eraseShape,
  dropShape
}
