import store from '@/store'
import router from '@/router'
import eventHub from '@/utils/eventHub'

export const saveJwtToStorage = (token) => {
  return window.localStorage.setItem('jwt', token)
}

export const deleteJwtFromStorage = () => {
  return window.localStorage.removeItem('jwt')
}

export const getJwtFromStorage = () => {
  return window.localStorage.getItem('jwt')
}

export const authGuard = (callback, message) => {
  if (store.getters['auth/isSignedIn']) {
    callback()
  } else
    store.commit('setModal', {
      component: 'Auth',
      data: { message, callback }
    })
}

export const proGuard = (callback, message) => {
  if (store.getters['auth/isProAccount']) {
    callback()
  } else
    store.commit('setModal', {
      component: 'Upgrade',
      data: { message, callback }
    })
}

addEventListener('storage', (event) => {
  store.dispatch('auth/setUser', event.newValue)
  store.commit('setModal', null)
  eventHub.$emit('notify', {
    group: 'success',
    title: 'Logged into PatternKid!'
  })
  router.push('/workbench')
})
