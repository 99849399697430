<template>
  <div>
    <div id="support-container">
      <div class="is-hidden-mobile" id="left-img"></div>
      <div class="is-hidden-mobile" id="right-img"></div>
      <Navbar />
      <div class="container mt-6">
        <LayoutHeader title="PatternKid Support" />
      </div>
      <div class="container mb-6">
        <div class="columns is-centered">
          <div class="column is-12-mobile is-8-tablet is-6-desktop">
            <div class="brutal-border">
              <SupportContent />
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
export default {
  name: 'Landing',
  components: {
    Navbar: () => import('@/components/landing/LandingNavbar.vue'),
    LayoutHeader: () => import('@/components/layout/LayoutHeader.vue'),
    SupportContent: () => import('@/components/support/SupportContent.vue'),
    Footer: () => import('@/components/landing/LandingFooter.vue')
  }
}
</script>
<style scoped>
#support-container {
  position: relative;
}

#left-img {
  position: absolute;
  top: 47px;
  left: 0;
  bottom: 0;
  right: 50%;
  z-index: -1;
  background-image: url('https://pattern-kid-main.s3.eu-west-1.amazonaws.com/assets/pricing-left.png');
  background-position: top right;
  background-repeat: no-repeat;
  background-size: cover;
}

#right-img {
  position: absolute;
  top: 47px;
  left: 50%;
  bottom: 0;
  right: 0;
  z-index: -1;
  background-image: url('https://pattern-kid-main.s3.eu-west-1.amazonaws.com/assets/pricing-right.png');
  background-position: top left;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
