import Vue from 'vue'
import App from './App.vue'
import Notifications from 'vue-notification'
import VueCroppie from 'vue-croppie'
import VueMeta from 'vue-meta'
import VueWait from 'vue-wait'
import VueTippy from 'vue-tippy'

import store from './store'
import router from './router'

import '@/assets/main.scss'
import '@/assets/extensions.scss'
import '@/assets/transitions.scss'
import '@/utils/eventHub'
import 'croppie/croppie.css'
import 'tippy.js/themes/light-border.css'

Vue.config.productionTip = false
Vue.use(VueCroppie)
Vue.use(VueWait)
Vue.use(VueMeta)
Vue.use(Notifications)
Vue.use(VueTippy, {
  arrow: true,
  theme: 'light-border',
  delay: [400, 0],
  touchHold: store.getters['isTablet']
})

new Vue({
  store,
  router,
  wait: new VueWait(),
  render: (h) => h(App)
}).$mount('#app')
