import store from '@/store'
import settings from '@/utils/settings'

export const clickShape = async (e, action) => {
  const mouse = window.canvas.getPointer(e)
  const mouseOrigin = getMouseOrigin(mouse)
  const clickedObjects = []

  window.canvas.forEachObject((object) => {
    if (
      between(mouse.x, object.left, object.left + settings.resolution * object.scaleX) &&
      between(mouse.y, object.top, object.top + settings.resolution * object.scaleY)
    ) {
      if (object.pk_type === 'background') return

      const path = object.getPath()
      const angle = object.getAngle()
      const shapeOrigin = getShapeOrigin(object)
      const shapeOffset = getShapeOffset(mouseOrigin, shapeOrigin)

      const scaledMouse = getScaledMouse(mouse, mouseOrigin, object, shapeOffset)

      if (checkPath(path, angle, scaledMouse)) {
        clickedObjects.push(object)
      }
    }
  })

  if (clickedObjects.length) {
    const object = clickedObjects[clickedObjects.length - 1]
    await action(object)
  }
}

const createPath = (ctx, path) => {
  for (let element of path) {
    if (element[0] === 'M') {
      ctx.moveTo(element[1], element[2])
    }
    if (element[0] === 'L') {
      ctx.lineTo(element[1], element[2])
    }
    if (element[0] === 'C') {
      ctx.bezierCurveTo(element[1], element[2], element[3], element[4], element[5], element[6])
    }
  }
}

export const checkPath = (path, angle, mouse) => {
  const canvas = document.createElement('canvas')
  canvas.width = settings.resolution
  canvas.height = settings.resolution
  const ctx = canvas.getContext('2d')

  ctx.translate(settings.resolution / 2, settings.resolution / 2)
  ctx.rotate((angle * Math.PI) / 180)
  ctx.translate(-settings.resolution / 2, -settings.resolution / 2)
  createPath(ctx, path)
  ctx.fill()

  return ctx.isPointInPath(mouse.x, mouse.y)
}

export const getScaledMouse = (mouse, mouseOrigin, object, shapeOffset) => {
  const textX = mouse.x - mouseOrigin.x + shapeOffset.x
  const textY = mouse.y - mouseOrigin.y + shapeOffset.y

  const x = (textX / (settings.resolution * object.scaleX)) * settings.resolution
  const y = (textY / (settings.resolution * object.scaleY)) * settings.resolution
  return {
    x,
    y
  }
}

const getMouseOrigin = (mouse) => {
  return {
    x: Math.floor(mouse.x / settings.resolution) * settings.resolution,
    y: Math.floor(mouse.y / settings.resolution) * settings.resolution
  }
}

const getShapeOrigin = (object) => {
  return {
    x:
      Math.floor(object.left / settings.resolution) * settings.resolution +
      store.getters['workbench/border'],
    y:
      Math.floor(object.top / settings.resolution) * settings.resolution +
      store.getters['workbench/border']
  }
}

const getShapeOffset = (mouseOrigin, shapeOrigin) => {
  return { y: mouseOrigin.y - shapeOrigin.y, x: mouseOrigin.x - shapeOrigin.x }
}

const between = (x, min, max) => {
  return x >= min && x <= max
}
