import Vue from 'vue'
import Router from 'vue-router'
import Landing from '@/pages/Landing.vue'
import Workbench from '@/pages/Workbench.vue'
import Support from '@/pages/Support.vue'
import Privacy from '@/pages/PrivacyNotice.vue'
import Terms from '@/pages/Terms.vue'
import SignIn from '@/pages/SignIn.vue'
import Magic from '@/pages/Magic.vue'
import Profile from '@/pages/Profile.vue'
import Collection from '@/pages/Collection.vue'
import Subscribe from '@/pages/Subscribe.vue'
import Pricing from '@/pages/Pricing.vue'
import Settings from '@/pages/Settings.vue'
import Bundles from '@/pages/Bundles.vue'
import store from '@/store'

Vue.use(Router)

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Landing',
      component: Landing
    },
    {
      path: '/workbench',
      name: 'Workbench',
      component: Workbench
    },
    {
      path: '/support',
      name: 'Support',
      component: Support
    },
    {
      path: '/privacy',
      name: 'Privacy',
      component: Privacy
    },
    {
      path: '/terms',
      name: 'Terms',
      component: Terms
    },
    {
      path: '/sign-in',
      name: 'SignIn',
      component: SignIn,
      beforeEnter(to, from, next) {
        if (store.getters['auth/isSignedIn']) {
          next('/workbench')
        } else next()
      }
    },
    {
      path: '/profile/:tab',
      name: 'Profile',
      component: Profile,
      beforeEnter(to, from, next) {
        if (store.getters['auth/isSignedIn']) {
          next()
        } else next('/sign-in')
      }
    },
    {
      path: '/collection',
      name: 'Collection',
      component: Collection
    },
    {
      path: '/magic',
      name: 'Magic',
      component: Magic
    },
    {
      path: '/pricing',
      name: 'Pricing',
      component: Pricing
    },
    {
      path: '/subscribe',
      name: 'Subscribe',
      component: Subscribe,
      beforeEnter(to, from, next) {
        if (!store.getters['auth/isProAccount']) {
          next()
        } else next('/workbench')
      }
    },
    { path: '/adobe', redirect: '/sign-in/adobe' },
    {
      path: '/settings',
      name: 'Settings',
      component: Settings
    },
    {
      path: '/bundles',
      name: 'Bundles',
      component: Bundles
    },
    { path: '/:catchAll(.*)', redirect: '/workbench' }
  ]
})

router.beforeEach((to, from, next) => {
  store.dispatch('auth/checkStorage')
  next()
})

export default router
