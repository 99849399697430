<template>
  <div id="app">
    <Notifications />
    <Modal />
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  metaInfo: {
    title: 'Generate beautiful geometric design assets.',
    titleTemplate: 'PatternKid. %s',
    htmlAttrs: {
      lang: 'en'
    },
    meta: [
      { charset: 'utf-8' },
      {
        name: 'description',
        content:
          'Design beautiful geometric design assets - perfect for landing pages, slide decks, social posts and more! Get started for free.'
      },
      {
        name: 'og:url',
        content: 'https://patternkid.com'
      },
      {
        name: 'og:type',
        content: 'website'
      },
      {
        name: 'og:title',
        content: 'PatternKid.'
      },
      {
        name: 'og:description',
        content:
          'Design beautiful geometric design assets - perfect for landing pages, slide decks, social posts and more! Get started for free.'
      },
      {
        name: 'og:image',
        content: 'https://pattern-kid-main.s3.eu-west-1.amazonaws.com/assets/hero/10.png'
      },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' }
    ]
  },
  components: {
    Notifications: () => import('@/components/notifications/Notifications.vue'),
    Modal: () => import('@/components/modals/ModalContainer.vue')
  }
}
</script>
