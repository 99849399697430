import settings from '@/utils/settings'
import { loadFont } from '@/services/font'
// import { createTextDataUrl } from '@/services/text'
import { addDataUrl } from './image'

export const addText = (object) => {
  const { font } = object.pk_text

  return new Promise((resolve) => {
    loadFont(font, async () => {
      // const textDataUrl = createTextDataUrl(text, font.family, object.pk_fill)
      const textDataUrl = ''

      const { scaleX: scale } = object
      const scaledDimension = settings.resolution * scale

      await addDataUrl(object, textDataUrl, scaledDimension)
      resolve()
    })
  })
}
