import axios from 'axios'
import debounce from 'lodash.debounce'
import store from '@/store'
import * as Auth from '@/utils/auth'

const debounceSignOut = debounce(() => {
  store.dispatch('auth/signOut')
}, 1000)

export const api = axios.create({
  baseURL: process.env.VUE_APP_API
})

api.interceptors.request.use((config) => {
  const jwt = Auth.getJwtFromStorage()
  config.headers.Authorization = jwt ? `Bearer ${jwt}` : ''
  return config
})

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      debounceSignOut()
    }
    throw error
  }
)
