<template>
  <div>
    <div id="bundles-container">
      <div class="is-hidden-mobile" id="left-img"></div>
      <div class="is-hidden-mobile" id="right-img"></div>
      <Navbar />
      <div class="container">
        <div class="mt-6">
          <LayoutHeader
            title="Free Bundles!"
            subtitle="Free asset bundles to help you get creative."
          />
        </div>
      </div>
      <div class="container">
        <div class="section is-medium">
          <div class="columns is-centered">
            <div class="column is-10 is-12-touch"><Cta /></div>
          </div>
        </div>
      </div>
      <div class="has-background-primary-light brutal-border-top">
        <div class="container">
          <div class="section is-medium"><BundlesList /></div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
export default {
  name: 'Landing',
  components: {
    Navbar: () => import('@/components/landing/LandingNavbar.vue'),
    LayoutHeader: () => import('@/components/layout/LayoutHeader.vue'),
    Cta: () => import('@/components/bundles/BundlesCta.vue'),
    BundlesList: () => import('@/components/bundles/BundlesList.vue'),
    Footer: () => import('@/components/landing/LandingFooter.vue')
  }
}
</script>
<style scoped>
#bundles-container {
  position: relative;
}

#left-img {
  position: absolute;
  top: 47px;
  left: 0;
  bottom: 0;
  right: 50%;
  z-index: -1;
  background-image: url('https://pattern-kid-main.s3.eu-west-1.amazonaws.com/assets/pricing-left.png');
  background-position: top right;
  background-repeat: no-repeat;
  background-size: cover;
}

#right-img {
  position: absolute;
  top: 47px;
  left: 50%;
  bottom: 0;
  right: 0;
  z-index: -1;
  background-image: url('https://pattern-kid-main.s3.eu-west-1.amazonaws.com/assets/pricing-right.png');
  background-position: top left;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
