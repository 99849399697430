import { api } from '@/utils/axios'

const getDefaultState = () => {
  return {
    profileTab: 'patterns',
    subscriptionPlan: 'pro-yearly',
    patterns: { results: [], total: 0 },
    currentPatternPage: 0,
    collections: { results: [], total: 0 },
    palettes: { results: [], total: 0 },
    images: { results: [], total: 0 }
  }
}

const store = {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    patterns: (state) => state.patterns,
    currentPatternPage: (state) => state.currentPatternPage,
    isLastPatternPage: (state) =>
      state.patterns.pageLength * (state.currentPatternPage + 1) >= state.patterns.total,
    subscriptionPlan: (state) => state.subscriptionPlan,
    allPatternsFetched: (state) => state.patterns.results.length === state.patterns.total,
    profileTab: (state) => state.profileTab,
    collections: (state) => state.collections,
    palettes: (state) => state.palettes,
    images: (state) => state.images
  },
  mutations: {
    resetState(state) {
      Object.assign(state, getDefaultState())
    },
    setPatterns(state, patterns) {
      state.patterns = patterns
    },
    setCurrentPatternPage(state, page) {
      state.currentPatternPage = page
    },
    deletePattern(state, uuid) {
      const index = state.patterns.results.map((result) => result.uuid).indexOf(uuid)
      if (index !== -1) {
        state.patterns.results.splice(index, 1)
        state.patterns.total--
      }
    },
    setSubscriptionPlan(state, subscriptionPlan) {
      state.subscriptionPlan = subscriptionPlan
    },
    setProfileTab(state, tab) {
      state.profileTab = tab
    },
    setCollections(state, collections) {
      state.collections = collections
    },
    setPalettes(state, palettes) {
      state.palettes = palettes
    },
    setImages(state, images) {
      state.images = images
    }
  },
  actions: {
    async getPatterns({ state, commit }) {
      const { data } = await api.get(`/api/pattern?page=${state.currentPatternPage}`)
      commit('setPatterns', data)
    },
    async getCollections({ commit }) {
      const { data } = await api.get('/api/collection')
      commit('setCollections', data)
    },
    async getPalettes({ commit }) {
      const { data } = await api.get('/api/palette')
      commit('setPalettes', data)
    },
    async getImages({ commit }) {
      if (this.getters['auth/isSignedIn']) {
        const { data } = await api.get('/api/image')
        commit('setImages', data)
      }
    },
    async createCollection({ commit }, body) {
      const { data } = await api.post('/api/collection', body)
      commit('setCollections', data)
    },
    async addToCollection({ commit }, body) {
      const { data } = await api.post('/api/collection/add', body)
      commit('setCollections', data)
    },
    async addImage({ commit }, base64) {
      const type = base64.match(/[^:/]\w+(?=;|,)/)[0]
      const { data } = await api.post('/api/image', {
        type,
        base64: base64.split(',')[1]
      })
      commit('setImages', data)
    }
  }
}

export default store
