var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{attrs:{"id":"pricing-container"}},[_c('div',{staticClass:"is-hidden-mobile",attrs:{"id":"left-img"}}),_c('div',{staticClass:"is-hidden-mobile",attrs:{"id":"right-img"}}),_c('Navbar'),_c('div',{staticClass:"container"},[_c('div',{staticClass:"section"},[_c('LayoutHeader',{attrs:{"title":"Pricing","subtitle":"Get started for free by exploring the pattern workbench or create a pro account to access all our features."}})],1),_c('div',{staticClass:"has-text-centered"},[_c('Toggle',{attrs:{"options":['monthly', 'yearly', 'forever!'],"action":_vm.setPeriod,"activeOption":_vm.period,"scheme":"black"}})],1),_c('div',{staticClass:"section"},[_c('div',{staticClass:"columns is-centered"},[_c('div',{staticClass:"column is-4-widescreen is-5-desktop is-6-tablet"},[_c('PricingOption',{attrs:{"title":"PatternKid Normalo","subtitle":"Create geometric assets with all our core pattern building features. Beloved by lighter content creators.","price":{ price: 'Free', period: '' },"action":() => _vm.$router.push('/workbench'),"actionMessage":"Get started for free","features":['Workbench', '3 pattern files', '3 saved palettes', '1 collection']}})],1),_c('div',{staticClass:"column is-4-widescreen is-5-desktop is-6-tablet"},[_c('PricingOption',{attrs:{"simple":_vm.simple,"title":"PatternKid Pro","subtitle":"Create and save unlimited geometric patterns. Beloved by pros and serious content creators.","price":_vm.proPrice,"action":() => _vm.$router.push('/subscribe'),"actionMessage":"Get started","features":[
                'Unlimited high definition downloads',
                'Unlimited pattern files',
                'Unlimited saved palettes',
                'Unlimited collections',
                'All templates',
                'All textures',
                'Early access to new features'
              ]}})],1)])])])],1),_c('PricingFaqs'),_c('Footer')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }