<template>
  <div id="sign-in" class="columns is-gapless is-mobile is-vcentered">
    <img
      @click="logoClicked"
      id="sign-in-logo"
      class="is-clickable"
      src="@/assets/imgs/logo-inline.png"
      alt="PatternKid logo"
    />
    <div class="column"></div>
    <div class="column is-narrow">
      <div id="sign-in-content" class="is-padded-12">
        <div class="columns is-centered">
          <div class="column is-narrow">
            <div
              class="title is-3 has-text-weight-bold has-text-black is-padded-bottom-12"
              style="max-width: 400px"
            >
              Sign in
            </div>
            <div class="subtitle" style="max-width: 400px">
              We'll sign you in if you have an account, or create one if not.
            </div>
            <Auth />
          </div>
        </div>
      </div>
    </div>
    <div class="column"></div>
    <div
      id="sign-in-img"
      class="column is-hidden-mobile is-3-tablet-only is-5-widescreen is-hidden-mobile"
    ></div>
  </div>
</template>
<script>
export default {
  name: 'SignIn',
  components: {
    Auth: () => import('@/components/auth/Auth.vue')
  },
  methods: {
    logoClicked() {
      this.$router.push('/workbench')
    }
  }
}
</script>
<style scoped>
#sign-in-logo {
  position: fixed;
  top: 8px;
  left: 8px;
  height: 46px;
}
#sign-in {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

#sign-in-img {
  height: 100%;
  background-image: url('/patterns/sign-in.jpeg');
  background-position: top left;
  background-repeat: no-repeat;
  background-size: cover;
}

@media screen and (max-width: 540px) {
  #sign-in-content {
    width: 300px;
  }
}

@media screen and (min-width: 540px) {
  #sign-in-content {
    height: 400px;
  }
}
</style>
