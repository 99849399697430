<template>
  <div id="subscribe" class="columns is-gapless is-mobile">
    <div
      id="subscribe-img"
      class="column is-hidden-mobile is-4-desktop is-4-tablet is-hidden-mobile has-background-info-light"
      style="height: 100vh"
    ></div>
    <div class="column" style="position: relative">
      <div class="columns is-mobile">
        <div class="column"></div>
        <div class="column is-narrow">
          <div @click="$router.push('/workbench')" class="button is-white has-margin-12">
            <span class="icon">
              <i class="fas fa-lg fa-times"></i>
            </span>
          </div>
        </div>
      </div>
      <div class="columns is-centered is-mobile" style="position: absolute; left: 0; right: 0">
        <div class="column is-4-widscreen is-8-desktop is-11-touch">
          <div v-if="!this.$route.query.session_id">
            <Header />
            <Payment />
          </div>
          <div v-else><Welcome /></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Subscribe',
  components: {
    Header: () => import('@/components/subscribe/SubscribeHeader.vue'),
    Payment: () => import('@/components/subscribe/SubscribePayment.vue'),
    Welcome: () => import('@/components/subscribe/SubscribeWelcome.vue')
  }
}
</script>
<style scoped>
#subscribe-img {
  background-image: url('/patterns/subscribe.jpeg');
  background-position: top right;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
